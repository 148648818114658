import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Browser from '@/support/browser';
import PlayerUtil from '@/support/playerUtil';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';

@Component
export default class StudioListComponent extends StudioPageBase {
    options = {
        hide: Browser.getBoolParam('hide', false),
        hideSideBar: Browser.getBoolParam("hideSideBar", false) || Browser.getBoolParam('playerOnly', false),
        title: Browser.getParamEscaped("title", null),
        debugSettings: Browser.getBoolParam('debugSettings', false),
    };

    loading: boolean = true;
    loaded: boolean = false;

    created() {
        super.created('studioList');

        Debug.setDebugModule('App', this);
    }

    mounted() {
        super.mounted();

        this.load();
    }

    showError(error: string, error2: string = null) {
        Debug.error("showError", error, error2);
        Util.showToast(error, true);
        //window.location.href = '/Error';
    }

    showNotAuthorized() {
        Debug.error("Not authorizied");
        window.location.href = '/NotAuthorized';
    }

    protected onLoaded(loaded: boolean) {
        super.onLoaded(loaded);

        if (!loaded || this.studios.length == 0) {
            PlayerUtil.redirectToNotAuthorized(this.$router);
            return;
        }

        if (this.studios.length == 1) {
            PlayerUtil.redirectToStudio(this.$router, this.studios[0].studio);
            return;
        }

        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    testShowToast(message) {
        Util.showToast(message || 'Missing message');
    }

}
